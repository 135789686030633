<template>
  <div>
    <div class="d-flex flex-wrap justify-space-around align-center">
      <div class="mt-4 mb-1" v-if="user">
        <v-alert
          v-if="user.landingMessage"
          border="left"
          colored-border
          type="error"
          elevation="5"
          icon="mdi-message-alert"
          max-width="344"
        >
          {{ user.landingMessage }}
        </v-alert>

        <v-card rounded="xl" elevation="5" max-width="344">
          <v-img
            :src="user.image"
            main-theme-color="deep-purple"
            max-width="344"
            class="align-end"
          >
            <v-card-title class="white--text" style="-webkit-text-stroke: 1px black">{{ user.firstName }}
              {{ user.lastName }}
            </v-card-title>
          </v-img>
          <v-card-subtitle class="deep-purple--text">{{ user.info }}</v-card-subtitle>
          <v-card-subtitle class="deep-purple--text d-flex justify-space-between align-center">Телефон: {{ user.phone }}
            <v-btn small color="deep-purple lighten-1" dark elevation="0" :href="`tel:${user.phone}`">
              Позвонить
            </v-btn>
          </v-card-subtitle>
        </v-card>
      </div>

      <div class="mt-4 mb-1">
        <v-list min-width="344">
          <v-list-item-group>
            <v-list-item :class="empty? 'elevation-0' : 'elevation-5'" class="mx-1" to="/choose-category"
                         :disabled="empty">
              <v-list-item-icon>
                <v-icon large :disabled="empty" v-text="'mdi-lead-pencil'" color="deep-purple"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="empty ? 'Нет мест для записи' : 'Записаться'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="elevation-5 mt-2 mx-1" to="/client-records">
              <v-list-item-icon>
                <v-img src="/img/eyeOpen.png" height="36" width="36"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Посмотреть свои записи'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="elevation-5 mt-2 mx-1" href="https://vk.com/resnicyemva">
              <v-list-item-icon>
                <v-img src="/img/vk.png" height="36" width="36"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Я Вконтакте'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="elevation-5 mt-2 mx-1" href="https://api.whatsapp.com/send?phone=79042280684">
              <v-list-item-icon>
                <v-img src="/img/whatsapp.png" height="36" width="36"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Я в Whatsapp'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="elevation-5 mt-2 mx-1" href="https://t.me/+79042280684">
              <v-list-item-icon>
                <v-img src="/img/telegram.png" height="36" width="36"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Я в Telegram'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

<!--            <v-list-item class="elevation-5 mt-2 mx-1" href="https://www.instagram.com/novikova.lashess">-->
<!--              <v-list-item-icon>-->
<!--                <v-img src="/img/insta.png" height="36" width="36"></v-img>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title v-text="'Я в Instagram'"></v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->

            <v-list-item class="elevation-5 mt-2 mx-1" to="/price-list">
              <v-list-item-icon>
                <v-icon large v-text="'mdi-currency-rub'" color="deep-purple darken-3"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'Посмотреть прайс-лист'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="elevation-5 mt-2 mx-1" to="/master-info">
              <v-list-item-icon>
                <v-icon large v-text="'mdi-information-variant'" color="deep-purple darken-3"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'О мастере'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Landing',
  components: {},
  data: () => ({
    empty: false,
    user: null,
  }),
  methods: {
    async checkWins() {
      const id = this.$store.getters.ownerId
      let json = JSON.stringify({id})

      const response = await fetch(`${this.$store.state.server}/record/check-window-list-for-client`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: json,
      })

      json = await response.json()
      if (json.empty) {
        this.$store.commit("setServerMessage", json.message)
        this.empty = true
      }
    },

    async loadUserInfo() {
      const id = this.$store.getters.ownerId
      const json = await this.authFetch('/user/load-user-info', {id})
      if (json.status === 200) {
        this.user = json.user
      }
    },
  },
  async mounted() {
    this.loadUserInfo()
    await this.checkWins()
  },
}
</script>
